import React from "react";
import { motion } from "framer-motion";
import { LampContainer } from "./lamp.tsx";

export function LampDemo() {
  return (
    <LampContainer>
      <motion.h1
        initial={{ opacity: 0.5, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="mt-2 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center text-4xl font-medium tracking-tight text-transparent md:text-6xl font-[questrial]"
      >
        Seen our work? <br /> Still not convinced? <br />
        Seriously?
      </motion.h1>
    </LampContainer>
  );
}
